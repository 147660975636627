.big-box {
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: 5px;
  display: inline-block;
  font-size: 40px;
  border: 1px lightslategrey double;
  vertical-align: middle; }

@media (max-width: 480px) {
  .big-box {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px; } }

.bg-pink {
  background-color: pink; }

.bg-orange {
  background-color: orange; }

.bg-red {
  background-color: red; }

.bg-silver {
  background-color: silver; }

.bg-black {
  background-color: black; }

.bg-yellow {
  background-color: yellow; }

.bg-blue {
  background-color: blue; }

.bg-green {
  background-color: green; }

.bg-purple {
  background-color: purple; }

.c-red {
  color: red; }

.c-green {
  color: green; }

.c-yellow {
  color: yellow; }

.c-blue {
  color: #355390; }

.c-white {
  color: white; }

.fake-inv {
  background-color: lightblue !important;
  color: lightblue !important;
  border: none; }

.fake-textbox {
  background-color: lightblue;
  color: lightslategrey;
  border: lightslategrey 1px double;
  max-width: 100%;
  text-align: center; }

.fake-small {
  font-family: fantasy;
  text-align: center;
  font-size: 2px; }

.zigzag {
  height: 10px;
  background: linear-gradient(135deg, lightslategrey 35%, transparent 25%) -5px 0, linear-gradient(225deg, lightslategrey 35%, transparent 25%) -5px 0, linear-gradient(315deg, lightslategrey 35%, transparent 25%), linear-gradient(45deg, lightslategrey 35%, transparent 25%);
  background-size: 10px 10px;
  background-color: lightblue; }

.locker-center {
  font-size: 70px;
  font-family: fantasy;
  text-align: center;
  margin-top: 2vh; }
  .locker-center select {
    background-color: lightblue;
    color: lightslategrey; }
  .locker-center input {
    text-align: center; }
    .locker-center input::placeholder {
      font-family: Arial;
      font-size: 20px;
      font-style: italic; }
  .locker-center .line {
    display: inline-block;
    font-size: 40px; }

@media (max-width: 480px) {
  .locker-center {
    font-size: 25px; }
    .locker-center .line {
      display: inline-block;
      font-size: 25px; }
    .locker-center input::placeholder {
      font-size: 10px; } }

.molecule {
  position: relative;
  width: 200px;
  animation: brillito 3.5s;
  margin: auto;
  margin-top: 5vh;
  height: 170px; }
  .molecule .hydro {
    z-index: 2;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border-width: 10px;
    border-style: solid;
    background-color: white;
    color: white; }
    .molecule .hydro:nth-child(1) {
      left: 100px; }
  .molecule .oxy {
    left: 50px;
    top: 50px;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border-width: 10px;
    border-style: solid;
    background-color: red;
    color: red;
    z-index: 1; }

.rings {
  position: relative;
  width: 350px;
  animation: brillito 3.5s;
  margin: auto;
  margin-top: 5vh; }
  .rings .ring {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border-width: 10px;
    border-style: solid; }
    .rings .ring::after {
      content: "";
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      top: -10px;
      right: -10px;
      border: 10px solid transparent; }
    .rings .ring.ring-blue {
      color: #0082c9; }
    .rings .ring.ring-yellow {
      color: #fcb32e; }
    .rings .ring.ring-black {
      color: #000; }
    .rings .ring.ring-green {
      color: #30a751; }
    .rings .ring.ring-red {
      color: #ef314e; }
    .rings .ring.ring1 {
      left: 0; }
      .rings .ring.ring1::after {
        border-right: 10px solid currentcolor;
        z-index: 2; }
    .rings .ring.ring2 {
      top: 50px;
      left: 55px; }
      .rings .ring.ring2::after {
        border-top: 10px solid currentcolor;
        z-index: 1; }
    .rings .ring.ring3 {
      left: 110px; }
      .rings .ring.ring3::after {
        border-bottom: 10px solid currentcolor;
        z-index: 1; }
      .rings .ring.ring3::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        top: -10px;
        right: -10px;
        border: 10px solid transparent;
        border-right: 10px solid currentcolor;
        z-index: 2; }
    .rings .ring.ring4 {
      top: 50px;
      left: 165px;
      z-index: 1; }
      .rings .ring.ring4::after {
        border-left: 10px solid currentcolor;
        z-index: 1; }
    .rings .ring.ring5 {
      left: 220px; }
      .rings .ring.ring5::after {
        border-bottom: 10px solid currentcolor;
        z-index: 2; }

.ring-single {
  position: relative;
  width: 100px;
  animation: brillito 3.5s;
  margin: auto;
  margin-top: 5vh;
  height: 125px; }
  .ring-single .ring {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border-width: 10px;
    border-style: solid;
    left: 0; }

.tips-center {
  font-size: 70px;
  font-family: fantasy;
  text-align: center;
  background-color: lightblue;
  color: white;
  font-family: fantasy; }
  .tips-center .line {
    display: inline-block;
    font-size: 50px;
    width: 60px;
    text-align: center;
    margin: 5px; }
  .tips-center .fit-text {
    font-family: monospace;
    font-size: 25px; }

.hide-tip {
  background-color: lightseagreen; }
  .hide-tip:after {
    content: "\003F";
    cursor: pointer;
    font-family: sans-serif; }
  .hide-tip .img-tip {
    display: none; }

@media (min-width: 481px) {
  .tip-box {
    width: 200px;
    height: 200px;
    line-height: 200px;
    margin: 5px;
    display: inline-block;
    font-size: 40px;
    border: 1px lightslategrey double;
    vertical-align: middle; }
  .img-tip {
    filter: invert(100%) sepia(0%) saturate(4347%) hue-rotate(296deg) brightness(109%) contrast(101%);
    width: 200px;
    height: 200px;
    background-size: 800px 800px; }
    .img-tip.alphabet {
      background-image: url("../../public/level/sprite-1.svg"); }
    .img-tip.keyboard {
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -200px 0px; }
    .img-tip.arrow-right {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -400px 0px; }
    .img-tip.speaker {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -600px 0px; }
    .img-tip.cloud-g {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: 0px -200px; }
    .img-tip.logo-g {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -200px -200px; }
    .img-tip.morse {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -400px -200px; }
    .img-tip.code {
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -600px -200px; }
    .img-tip.next-level-4 {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: 0px -400px; }
    .img-tip.cube {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -200px -400px; }
    .img-tip.axes {
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -400px -400px; }
    .img-tip.perspective {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -600px -400px; }
    .img-tip.guitar {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: 0px -600px; }
    .img-tip.rock-band {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -200px -600px; }
    .img-tip.armor {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: 0px 0px; }
    .img-tip.bank {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -200px 0px; }
    .img-tip.binary {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -400px 0px; }
    .img-tip.binary-count {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -600px 0px; }
    .img-tip.crash {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: 0px -200px; }
    .img-tip.distribution {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -200px -200px; }
    .img-tip.euclid {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -400px -200px; }
    .img-tip.divisible {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -600px -200px; }
    .img-tip.katana {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: 0px -400px; }
    .img-tip.market {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -200px -400px; }
    .img-tip.structure-molecule {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -400px -400px; }
    .img-tip.next-level-7 {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -600px -400px; }
    .img-tip.shuriken {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: 0px -600px; }
    .img-tip.tear-drop {
      filter: invert(33%) sepia(99%) saturate(639%) hue-rotate(165deg) brightness(107%) contrast(99%);
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -200px -600px; }
    .img-tip.turtle {
      filter: invert(15%) sepia(42%) saturate(981%) hue-rotate(111deg) brightness(94%) contrast(102%);
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -400px -600px; }
    .img-tip.ww1 {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -600px -600px; }
    .img-tip.italy {
      filter: none;
      background-image: url("../../public/level/sprite-3.svg");
      background-position: 0px 0px; }
    .img-tip.paint-brush {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -200px 0px; }
    .img-tip.engineering {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -400px 0px; }
    .img-tip.vitruvian-man {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -600px 0px; }
    .img-tip.bolt {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: 0px -200px; }
    .img-tip.hand-god {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -200px -200px; }
    .img-tip.father {
      filter: none;
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -400px -200px; }
    .img-tip.zeta {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -600px -200px; }
    .img-tip.greece {
      filter: none;
      background-image: url("../../public/level/sprite-3.svg");
      background-position: 0px -400px; }
    .img-tip.area {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -200px -400px; }
    .img-tip.numbers {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -400px -400px; }
    .img-tip.f-legs {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -600px -400px; }
    .img-tip.companion {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-3.svg");
      background-position: 0px -600px; }
    .img-tip.animal {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -200px -600px; }
    .img-tip.frisbee {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -400px -600px; }
    .img-tip.snake {
      filter: none;
      background-image: url("../../public/level/sprite-4.svg");
      background-position: 0px 0px; }
    .img-tip.mirror {
      filter: none;
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -200px 0px; }
    .img-tip.rock {
      filter: none;
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -400px 0px; }
    .img-tip.perseus {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -600px 0px; }
    .img-tip.gorgon {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: 0px -200px; }
    .img-tip.broken-heart {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -200px -200px; }
    .img-tip.line-heart {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -400px -200px; }
    .img-tip.code-blue {
      filter: none;
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -600px -200px; }
    .img-tip.defib {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-4.svg");
      background-position: 0px -400px; }
    .img-tip.leet-code {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -200px -400px; }
    .img-tip.inv-image {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -400px -400px; }
    .img-tip.num-letter {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -600px -400px; }
    .img-tip.plant-root {
      filter: none;
      background-image: url("../../public/level/sprite-4.svg");
      background-position: 0px -600px; }
    .img-tip.sun {
      filter: invert(74%) sepia(99%) saturate(964%) hue-rotate(343deg) brightness(97%) contrast(106%);
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -200px -600px; }
    .img-tip.co2 {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -400px -600px; }
    .img-tip.wbc {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: 0px 0px; }
    .img-tip.body {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -200px 0px; }
    .img-tip.medecine {
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -400px 0px; }
    .img-tip.wolbachia {
      filter: invert(20%) sepia(40%) saturate(3881%) hue-rotate(262deg) brightness(92%) contrast(130%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -600px 0px; }
    .img-tip.matter-change {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: 0px -200px; }
    .img-tip.matter {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -200px -200px; }
    .img-tip.texas {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -400px -200px; }
    .img-tip.img-number {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -600px -200px; }
    .img-tip.group-number {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: 0px -400px; }
    .img-tip.server {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -200px -400px; }
    .img-tip.users {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -400px -400px; }
    .img-tip.services {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -600px -400px; }
    .img-tip.requester {
      background-image: url("../../public/level/sprite-5.svg");
      background-position: 0px -600px; }
    .img-tip.requester {
      background-image: url("../../public/level/sprite-5.svg");
      background-position: 0px -600px; }
    .img-tip.paper-plant {
      filter: invert(54%) sepia(14%) saturate(1461%) hue-rotate(43deg) brightness(91%) contrast(88%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -200px -600px; }
    .img-tip.paper {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -400px -600px; }
    .img-tip.egypt {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -600px -600px; } }

@media (max-width: 480px) {
  .tip-box {
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin: 5px;
    display: inline-block;
    font-size: 40px;
    border: 1px lightslategrey double;
    vertical-align: middle; }
  .img-tip {
    filter: invert(100%) sepia(0%) saturate(4347%) hue-rotate(296deg) brightness(109%) contrast(101%);
    width: 100px;
    height: 100px;
    background-size: 400px 400px; }
    .img-tip.alphabet {
      background-image: url("../../public/level/sprite-1.svg"); }
    .img-tip.keyboard {
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -100px 0px; }
    .img-tip.arrow-right {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -200px 0px; }
    .img-tip.speaker {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -300px 0px; }
    .img-tip.cloud-g {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: 0px -100px; }
    .img-tip.logo-g {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -100px -100px; }
    .img-tip.morse {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -200px -100px; }
    .img-tip.code {
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -300px -100px; }
    .img-tip.next-level-4 {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: 0px -200px; }
    .img-tip.cube {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -100px -200px; }
    .img-tip.axes {
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -200px -200px; }
    .img-tip.perspective {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -300px -200px; }
    .img-tip.guitar {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: 0px -300px; }
    .img-tip.rock-band {
      filter: none;
      background-image: url("../../public/level/sprite-1.svg");
      background-position: -100px -300px; }
    .img-tip.armor {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: 0px 0px; }
    .img-tip.bank {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -100px 0px; }
    .img-tip.binary {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -200px 0px; }
    .img-tip.binary-count {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -300px 0px; }
    .img-tip.crash {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: 0px -100px; }
    .img-tip.distribution {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -100px -100px; }
    .img-tip.euclid {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -200px -100px; }
    .img-tip.divisible {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -300px -100px; }
    .img-tip.katana {
      filter: none;
      background-image: url("../../public/level/sprite-2.svg");
      background-position: 0px -200px; }
    .img-tip.market {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -100px -200px; }
    .img-tip.structure-molecule {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -200px -200px; }
    .img-tip.next-level-7 {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -300px -200px; }
    .img-tip.shuriken {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: 0px -300px; }
    .img-tip.tear-drop {
      filter: invert(33%) sepia(99%) saturate(639%) hue-rotate(165deg) brightness(107%) contrast(99%);
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -100px -300px; }
    .img-tip.turtle {
      filter: invert(15%) sepia(42%) saturate(981%) hue-rotate(111deg) brightness(94%) contrast(102%);
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -200px -300px; }
    .img-tip.ww1 {
      background-image: url("../../public/level/sprite-2.svg");
      background-position: -300px -300px; }
    .img-tip.italy {
      filter: none;
      background-image: url("../../public/level/sprite-3.svg");
      background-position: 0px 0px; }
    .img-tip.paint-brush {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -100px 0px; }
    .img-tip.engineering {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -200px 0px; }
    .img-tip.vitruvian-man {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -300px 0px; }
    .img-tip.bolt {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: 0px -100px; }
    .img-tip.hand-god {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -100px -100px; }
    .img-tip.father {
      filter: none;
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -200px -100px; }
    .img-tip.zeta {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -300px -100px; }
    .img-tip.greece {
      filter: none;
      background-image: url("../../public/level/sprite-3.svg");
      background-position: 0px -200px; }
    .img-tip.area {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -100px -200px; }
    .img-tip.numbers {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -200px -200px; }
    .img-tip.f-legs {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -300px -200px; }
    .img-tip.companion {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-3.svg");
      background-position: 0px -300px; }
    .img-tip.animal {
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -100px -300px; }
    .img-tip.frisbee {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-3.svg");
      background-position: -200px -300px; }
    .img-tip.snake {
      filter: none;
      background-image: url("../../public/level/sprite-4.svg");
      background-position: 0px 0px; }
    .img-tip.mirror {
      filter: none;
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -100px 0px; }
    .img-tip.rock {
      filter: none;
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -200px 0px; }
    .img-tip.perseus {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -300px 0px; }
    .img-tip.gorgon {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: 0px -100px; }
    .img-tip.broken-heart {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -100px -100px; }
    .img-tip.line-heart {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -200px -100px; }
    .img-tip.code-blue {
      filter: none;
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -300px -100px; }
    .img-tip.defib {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-4.svg");
      background-position: 0px -200px; }
    .img-tip.leet-code {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -100px -200px; }
    .img-tip.inv-image {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -200px -200px; }
    .img-tip.num-letter {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -300px -200px; }
    .img-tip.plant-root {
      filter: none;
      background-image: url("../../public/level/sprite-4.svg");
      background-position: 0px -300px; }
    .img-tip.sun {
      filter: invert(74%) sepia(99%) saturate(964%) hue-rotate(343deg) brightness(97%) contrast(106%);
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -100px -300px; }
    .img-tip.co2 {
      background-image: url("../../public/level/sprite-4.svg");
      background-position: -200px -300px; }
    .img-tip.wbc {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: 0px 0px; }
    .img-tip.body {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -100px 0px; }
    .img-tip.medecine {
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -200px 0px; }
    .img-tip.wolbachia {
      filter: invert(20%) sepia(40%) saturate(3881%) hue-rotate(262deg) brightness(92%) contrast(130%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -300px 0px; }
    .img-tip.matter-change {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: 0px -100px; }
    .img-tip.matter {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -100px -100px; }
    .img-tip.texas {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -200px -100px; }
    .img-tip.img-number {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -300px -100px; }
    .img-tip.group-number {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: 0px -200px; }
    .img-tip.server {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -100px -200px; }
    .img-tip.users {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -200px -200px; }
    .img-tip.services {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -300px -200px; }
    .img-tip.requester {
      background-image: url("../../public/level/sprite-5.svg");
      background-position: 0px -300px; }
    .img-tip.requester {
      background-image: url("../../public/level/sprite-5.svg");
      background-position: 0px -300px; }
    .img-tip.paper-plant {
      filter: invert(54%) sepia(14%) saturate(1461%) hue-rotate(43deg) brightness(91%) contrast(88%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -100px -300px; }
    .img-tip.paper {
      filter: invert(52%) sepia(6%) saturate(1057%) hue-rotate(169deg) brightness(99%) contrast(91%);
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -200px -300px; }
    .img-tip.egypt {
      filter: none;
      background-image: url("../../public/level/sprite-5.svg");
      background-position: -300px -300px; }
  .tips-center {
    font-size: 25px; }
    .tips-center .fit-text {
      font-size: 11px; } }

.transition-fadeout {
  visibility: hidden;
  opacity: 0;
  transition: visibility 2s 0s, opacity 2s linear; }

.transition-fadein {
  animation: fadeIn 2s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.level-center {
  margin-top: 10vh;
  font-size: 80px;
  font-family: fantasy;
  text-align: center; }

.level-no-access {
  margin-top: 20vh;
  font-size: 50px;
  font-family: serif;
  text-align: center;
  color: green; }

.congrats-center {
  margin-top: 10vh;
  font-size: 50px;
  font-family: fantasy;
  text-align: center;
  color: orange; }

.out-of-screen {
  margin-top: calc(100vh + 20vh); }

@media (max-width: 480px) {
  .level-center {
    font-size: 35px; }
  .congrats-center {
    font-size: 15px; }
  .level-no-access {
    margin-top: 10vh;
    font-size: 35px; }
  .out-of-screen {
    margin-top: calc(100vh + 40vh); } }
