.big-box {
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: 5px;
  display: inline-block;
  font-size: 40px;
  border: 1px lightslategrey double;
  vertical-align: middle; }

@media (max-width: 480px) {
  .big-box {
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px; } }

.css-checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.css-checkbox + label {
  position: relative;
  font-size: 14px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: black; }

.css-checkbox + label::before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  width: calc(min(8vh, 8vw));
  height: calc(min(8vh, 8vw));
  background-color: lightblue; }

.css-checkbox:checked + label::after {
  content: " ";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSIjNzc4ODk5IiBkPSJNMCA5NkMwIDYwLjY1IDI4LjY1IDMyIDY0IDMySDM4NEM0MTkuMyAzMiA0NDggNjAuNjUgNDQ4IDk2VjQxNkM0NDggNDUxLjMgNDE5LjMgNDgwIDM4NCA0ODBINjRDMjguNjUgNDgwIDAgNDUxLjMgMCA0MTZWOTZ6Ii8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: center center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  text-align: center;
  background-color: transparent;
  font-size: 10px;
  width: calc(min(8vh, 8vw));
  height: calc(min(8vh, 8vw)); }

.bg-pink {
  background-color: pink; }

.bg-orange {
  background-color: orange; }

.bg-red {
  background-color: red; }

.bg-silver {
  background-color: silver; }

.bg-black {
  background-color: black; }

.bg-yellow {
  background-color: yellow; }

.bg-blue {
  background-color: blue; }

.bg-green {
  background-color: green; }

.bg-purple {
  background-color: purple; }

.c-red {
  color: red; }

.c-green {
  color: green; }

.c-yellow {
  color: yellow; }

.c-blue {
  color: #355390; }

.c-white {
  color: white; }

.transition-fadeout {
  visibility: hidden;
  opacity: 0;
  transition: visibility 2s 0s, opacity 2s linear; }

.transition-fadein {
  animation: fadeIn 2s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.puzzle {
  background-color: lightslategrey;
  color: lightblue;
  text-align: center;
  cursor: pointer;
  margin: 10px; }
  .puzzle.selected {
    background-color: green; }

.tb-nonogram {
  margin: auto;
  margin-top: 2vh; }
  .tb-nonogram thead {
    vertical-align: bottom; }
    .tb-nonogram thead th {
      border: 1px solid;
      text-align: center; }
      .tb-nonogram thead th:nth-child(1) {
        border: none; }
  .tb-nonogram tbody td {
    text-align: right;
    border: 1px solid;
    width: calc(min(8vh, 8vw));
    height: calc(min(8vh, 8vw)); }
    .tb-nonogram tbody td span {
      margin: 5px;
      font-weight: 700; }

.tb-sudoku {
  margin: auto;
  margin-top: 2vh;
  border: #666699 solid 2px; }
  .tb-sudoku tbody tr:first-child {
    border-top: #666699 solid 2px; }
  .tb-sudoku tbody tr:nth-child(3n) td {
    border-bottom: #666699 solid 2px; }
  .tb-sudoku tbody td {
    border: 1px solid;
    text-align: center;
    width: calc(min(8vh, 8vw));
    height: calc(min(8vh, 8vw)); }
    .tb-sudoku tbody td input {
      border: none;
      background-color: lightblue;
      caret-color: transparent;
      text-align: center;
      width: calc(min(8vh, 8vw));
      height: calc(min(8vh, 8vw)); }
    .tb-sudoku tbody td:first-child {
      border-left: #666699 solid 2px; }
    .tb-sudoku tbody td:nth-child(3n) {
      border-right: #666699 solid 2px; }

.main-container .svg-container {
  margin: 10vh 0vh 0vh 10vw;
  width: 80vw;
  height: 80vh;
  cursor: pointer; }
  .main-container .svg-container .droppable {
    display: flex;
    flex-direction: row; }
  .main-container .svg-container .draggingOver {
    overflow: auto; }
  .main-container .svg-container .svg-draggable {
    width: 10vw;
    height: 10vw; }
    .main-container .svg-container .svg-draggable .svg-letters {
      width: 10vw;
      height: 10vw; }
      .main-container .svg-container .svg-draggable .svg-letters circle {
        fill: lightblue;
        stroke: lightslategrey;
        transform: translate(5vw, 5vw); }
      .main-container .svg-container .svg-draggable .svg-letters text {
        stroke: lightslategrey;
        font-weight: 700;
        font-family: "monospace";
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transform: translate(calc(5vw - 5px), calc(5vw + 5px));
        -ms-transform: translate(calc(5vw - 5px), calc(5vw + 5px));
        -webkit-transform: translate(calc(5vw - 5px), calc(5vw + 5px));
        -o-transform: translate(calc(5vw - 5px), calc(5vw + 5px));
        -moz-transform: translate(calc(5vw - 5px), calc(5vw + 5px)); }
    .main-container .svg-container .svg-draggable.dragging circle {
      fill: lightslategrey;
      stroke: lightblue; }
    .main-container .svg-container .svg-draggable.dragging text {
      stroke: lightblue; }

.item-0 > .lightbulb {
  color: lightslategrey; }

.item-1 > .lightbulb {
  color: lightgoldenrodyellow; }

.item-0 > .chameleon {
  color: lightslategrey; }

.item-1 > .chameleon {
  color: green; }

.item-0 > .sun {
  color: lightslategrey; }

.item-1 > .sun {
  color: #FDB813; }

.item-0 > .cloud {
  color: #3D3939; }

.item-1 > .cloud {
  color: #F4FAFC; }

@keyframes hideImg {
  0%, 70% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

.img-box {
  display: inline-block;
  font-size: 40px;
  border: 1px lightslategrey double;
  vertical-align: middle;
  margin: 5px;
  width: calc(min(12vw, 12vh));
  height: calc(min(12vw, 12vh));
  line-height: calc(min(12vw, 12vh)); }
  .img-box .img {
    width: calc(min(12vw, 12vh));
    height: calc(min(12vw, 12vh));
    background-size: calc(min(12vw, 12vh)*4) calc(min(12vw, 12vh)*4);
    transform: scale(0);
    animation: 5s hideImg linear;
    transition: transform 0.5s; }
    .img-box .img.locked {
      transform: scale(1); }
  .img-box .img-animals {
    background-image: url("../../public/level/sprite-animals.svg"); }
  .img-box .img-tools {
    background-image: url("../../public/level/sprite-tools.svg"); }
  .img-box .img-fruits {
    background-image: url("../../public/level/sprite-fruits.svg"); }
  .img-box .img-1 {
    background-position: calc(-1*min(12vw, 12vh)) 0px; }
  .img-box .img-2 {
    background-position: calc(-2*min(12vw, 12vh)) 0px; }
  .img-box .img-3 {
    background-position: calc(-3*min(12vw, 12vh)) 0px; }
  .img-box .img-4 {
    background-position: 0px calc(-1*min(12vw, 12vh)); }
  .img-box .img-5 {
    background-position: calc(-1*min(12vw, 12vh)) calc(-1*min(12vw, 12vh)); }
  .img-box .img-6 {
    background-position: calc(-2*min(12vw, 12vh)) calc(-1*min(12vw, 12vh)); }
  .img-box .img-7 {
    background-position: calc(-3*min(12vw, 12vh)) calc(-1*min(12vw, 12vh)); }
  .img-box .img-8 {
    background-position: 0px calc(-2*min(12vw, 12vh)); }
  .img-box .img-9 {
    background-position: calc(-1*min(12vw, 12vh)) calc(-2*min(12vw, 12vh)); }

@media (orientation: portrait) {
  .main-container .svg-container {
    margin: 0; }
    .main-container .svg-container .droppable {
      flex-direction: column;
      margin: auto;
      width: 10vh; }
    .main-container .svg-container .svg-draggable {
      width: 10vh;
      height: 10vh;
      margin: auto; }
      .main-container .svg-container .svg-draggable .svg-letters {
        width: 10vh;
        height: 10vh; }
        .main-container .svg-container .svg-draggable .svg-letters circle {
          transform: translate(5vh, 5vh); }
        .main-container .svg-container .svg-draggable .svg-letters text {
          transform: translate(calc(5vh - 5px), calc(5vh + 5px));
          -ms-transform: translate(calc(5vh - 5px), calc(5vh + 5px));
          -webkit-transform: translate(calc(5vh - 5px), calc(5vh + 5px));
          -o-transform: translate(calc(5vh - 5px), calc(5vh + 5px));
          -moz-transform: translate(calc(5vh - 5px), calc(5vh + 5px)); } }
